<script>
import { Statistic } from 'ant-design-vue'
import moment from 'moment'
const { Countdown } = Statistic

export default {
  props: {
    deadline: {
      type: String,
      default: 0
    },
    format: {
      type: String,
      default: 'HH:mm:ss'
    }
  },
  data() {
    return {}
  },
  render() {
    return (
      <Countdown
        value={this.deadline}
        format={this.format}
        valueStyle={{
          fontSize: '16px',
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      />
    )
  }
}
</script>

<style lang="less" scoped></style>
