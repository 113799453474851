<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import CountDown from '@/components/CountDown'
import moment from 'moment'

export default {
  name: 'bulkDetail',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getList.call(this, {
        url: '/peopleTeam/teamInfo',
        current: 1,
        paramsValue: {
          id
        }
      })
    }
  },
  methods: {
    getHeader() {
      return [
        {
          label: '名称',
          type: 'text'
        },
        {
          label: '有效期',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'startDate',
          title: '开团时间',
          align: 'left',
          sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
        },
        {
          dataIndex: 'endDate',
          title: '剩余时间',
          align: 'left',
          sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
          customRender: (text, records) => {
            return records.status == '2' && <CountDown deadline={text ?? 0} />
          }
        },
        {
          dataIndex: 'nowNum',
          title: '成团情况（份、人）',
          align: 'left',
          customRender: (text, records) => {
            return `${text}/${records.teamNum}（${((parseInt(text) / parseInt(records.teamNum)) * 100).toFixed(0)}%）`
          },
          onExport: (text, records) => {
            return `${text}/${records.teamNum}（${((parseInt(text) / parseInt(records.teamNum)) * 100).toFixed(0)}%）`
          }
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '拼团成功',
              value: '0'
            },
            {
              text: '拼团失败',
              value: '1'
            },
            {
              text: '拼团中',
              value: '2'
            }
          ],
          onExport: records => {
            return ['拼团成功', '拼团失败', '拼团中'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '拼团成功' : data == 1 ? '拼团失败' : '拼团中',
              color: data == 0 ? 'green' : data == 1 ? 'red' : '#ddd'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: '8%',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: '查看订单',
                onClick: () => this.$router.push('/orderProcessing/localProducts?userTeamId=' + records.id)
              }
              //   {  //需求变更不要删除按钮了
              //     display: true,
              //     name: '删除',
              //     type: 'pop',
              //     popTitle: '确认是否删除吗?',
              //     onClick: () => {
              //       api.command.del.call(this, {
              //         url: `/peopleTeam/deleteTeamInfo/${records.id}`
              //       })
              //     }
              //   }
            ].filter(e => e.display)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            getAction('/turntableModule/moduleList', {}, '/api').then(res => {
              if (res.code == 200 && res.data.length > 0) {
                this.$router.push('/marketingCenter/bigWheelDetail')
              } else {
                this.$message.warning('转盘位置已无，不可添加！')
              }
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={[]}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
